<template>
  <div
    class="md-layout md-size-100 mx-auto message"
    v-bind:class="{ user: isUser }"
  >
    <div class="md-layout-item md-size-5">
      <md-avatar class="md-small">
        <img class="img-raised rounded img-fluid" :src="picture" />
      </md-avatar>
    </div>
    <div class="md-layout-item">
      <span v-if="status.text">{{ status.text }} </span>
      <span v-if="status.emoji">{{ status.emoji }} </span>
      <span v-if="status.battery">Battery: {{ status.battery }}</span>
      <p>{{ status.created_at }}</p>
    </div>
  </div>
</template>

<script>
// <md-content v-bind:class="[isUser ? right : left]">
export default {
  name: "Message",
  props: {
    status: Object,
    isUser: Boolean,
    picture: {
      type: String,
      default: require("@/assets/img/faces/John.png"),
    },
  },
  data() {
    return {
      left: "md-accent text-left ",
      right: "md-primary text-right ",
    };
  },
};
</script>

<style scope>
.message {
  border-radius: 10px;
  border-width: 1px;
  background: #a1a1a1;
  margin-bottom: 5px;
  padding-top: 5px;
}

.user {
  border-radius: 10px;
  border-width: 1px;
  background: #d0e6b6;
  margin-bottom: 5px;
  padding-top: 5px;
}
</style>
