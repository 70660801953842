<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100
            "
          >
            <h1 class="title">Control-IT</h1>
            <h4>
              Every landing page needs a small description after the big bold
              title, that's why we added this text here. Add here all the
              information that can make you or your product create the first
              impression.
            </h4>
            <br />
            <md-button
              href="https://www.amazon.de"
              class="md-success md-lg"
              target="_blank"
              ><i class="fas fa-book"></i>Kaufe das Buch!</md-button
            >
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div
              class="
                md-layout-item md-size-66 md-xsmall-size-100
                mx-auto
                text-center
              "
            >
              <h2 class="title text-center">Control-Richtlinien</h2>
              <h5 class="description">
                Mit der Teilnahme an dem Programm stimmst du einer aktiven
                Mitarbeit für 4 Wochen zu, dazu gehört pro Woche einmal 45
                Minuten am VollControl Training teilzunehmen.
              </h5>
            </div>
          </div>
          <div class="features text-center">
            <div class="md-layout">
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-info">
                    <md-icon>security</md-icon>
                  </div>
                  <h4 class="info-title">Datenschutz</h4>
                  <p>
                    Ehrlichkeit ist ein Schlüssel zum Erfolg. Alle deine Angaben
                    und Daten werden vertraulich behandelt und bleiben nur
                    zwischen dir und der durchführenden Person.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-success">
                    <md-icon>chat</md-icon>
                  </div>
                  <h4 class="info-title">Respekt und Wertschätzung</h4>
                  <p>
                    Genauso, wie du ernst genommen und respektiert werden willst
                    und wirst, wird auch von dir erwartet, den Anleitungen der
                    durchführenden Person Folge zu leisten und sie zu
                    respektieren. Über alle Uneinigkeiten kann geredet werden.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-danger">
                    <md-icon>verified_user</md-icon>
                  </div>
                  <h4 class="info-title">Sicherheit</h4>
                  <p>
                    Mit der Teilnahme stimmst du einer durchgehenden Teilnahme
                    an den 4 Sitzungen à 45 Minuten zu. Mit Sicherheit wird sich
                    das Dranbleiben auszahlen!
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-danger">
                    <md-icon>fingerprint</md-icon>
                  </div>
                  <h4 class="info-title">Sicherheitstools</h4>
                  <p>
                    Um den Erfolg zu sichern, soll auch unter der Woche jeden
                    Tag der Control-IT Bogen ausgefüllt werden.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section text-center">
        <div class="container">
          <h2 class="title">Das ist unser Team</h2>
          <div class="team">
            <div class="md-layout">
              <div class="md-layout-item md-medium-size-33 md-small-size-100">
                <div class="team-player">
                  <md-card class="md-card-plain">
                    <div class="md-layout-item md-size-50 mx-auto">
                      <img
                        :src="teamImgAnna"
                        alt="Thumbnail Image"
                        class="img-raised rounded-circle img-fluid"
                      />
                    </div>
                    <h4 class="card-title">
                      Anna Mehlhorn
                      <br />
                      <small class="card-description text-muted">Autorin</small>
                    </h4>

                    <md-card-content>
                      <p class="card-description">
                        You can write here details about one of your team
                        members. You can give more details about what they do.
                        Feel free to add some <a href="#">links</a> for people
                        to be able to follow them outside the site.
                      </p>
                    </md-card-content>

                    <md-card-actions class="text-center">
                      <md-button
                        href="javascript:void(0)"
                        class="md-just-icon md-simple"
                      >
                        <i class="fab fa-twitter"></i>
                      </md-button>
                      <md-button
                        href="javascript:void(0)"
                        class="md-just-icon md-simple"
                      >
                        <i class="fab fa-linkedin"></i>
                      </md-button>
                    </md-card-actions>
                  </md-card>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-33 md-small-size-100">
                <div class="team-player">
                  <md-card class="md-card-plain">
                    <div class="md-layout-item md-size-50 mx-auto">
                      <img
                        :src="teamImgNico"
                        alt="Thumbnail Image"
                        class="img-raised rounded-circle img-fluid"
                      />
                    </div>
                    <h4 class="card-title">
                      Nico Inhoffen
                      <br />
                      <small class="card-description text-muted"
                        >Entwickler</small
                      >
                    </h4>

                    <md-card-content>
                      <p class="card-description">
                        You can write here details about one of your team
                        members. You can give more details about what they do.
                        Feel free to add some <a href="#">links</a> for people
                        to be able to follow them outside the site.
                      </p>
                    </md-card-content>

                    <md-card-actions class="text-center">
                      <md-button
                        href="javascript:void(0)"
                        class="md-just-icon md-simple"
                      >
                        <i class="fab fa-twitter"></i>
                      </md-button>
                      <md-button
                        href="javascript:void(0)"
                        class="md-just-icon md-simple"
                      >
                        <i class="fab fa-instagram"></i>
                      </md-button>
                      <md-button
                        href="javascript:void(0)"
                        class="md-just-icon md-simple"
                      >
                        <i class="fab fa-facebook-square"></i>
                      </md-button>
                    </md-card-actions>
                  </md-card>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-33 md-small-size-100">
                <div class="team-player">
                  <md-card class="md-card-plain">
                    <div class="md-layout-item md-size-50 mx-auto">
                      <img
                        :src="john"
                        alt="Thumbnail Image"
                        class="img-raised rounded-circle img-fluid"
                      />
                    </div>
                    <h4 class="card-title">
                      John
                      <br />
                      <small class="card-description text-muted">Kumpel</small>
                    </h4>

                    <md-card-content>
                      <p class="card-description">
                        Hey du! Na, alles klar? Ich heiße John und bin 13 Jahre
                        alt. Am liebsten häng ich mit meinen Jungs ab und hör
                        gern Hip Hop. Hin und wieder hatte ich ziemlich Stress
                        zu Hause und der Schule (voll nervig...), weil ich mich
                        einfach nicht unter Kontrolle hatte. Aber von jetzt an,
                        ist alles VOLL CONTROL! Wenn du das auch schaffen
                        willst, würde ich sagen: ControlStart!!!!
                      </p>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-contacts">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto">
              <h2 class="text-center title">Work with us</h2>
              <h4 class="text-center description">
                Divide details about your product or agency work into parts.
                Write a few lines about each one and contact us about any
                further collaboration. We will responde get back to you in a
                couple of hours.
              </h4>
              <form class="contact-form">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Your Name</label>
                      <md-input v-model="name" type="text"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Your Email</label>
                      <md-input v-model="email" type="email"></md-input>
                    </md-field>
                  </div>
                </div>
                <md-field maxlength="5">
                  <label>Your Message</label>
                  <md-textarea v-model="message"></md-textarea>
                </md-field>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 mx-auto text-center">
                    <md-button class="md-success">Send Message</md-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  bodyClass: "landing-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background1.png"),
    },
    john: {
      type: String,
      default: require("@/assets/img/faces/John.png"),
    },
    teamImgAnna: {
      type: String,
      default: require("@/assets/img/faces/anna.jpg"),
    },
    teamImgNico: {
      type: String,
      default: require("@/assets/img/faces/nico.jpg"),
    },
  },
  data() {
    return {
      name: null,
      email: null,
      message: null,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
