<template>
  <div class="wrapper">
    <div class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item
              md-size-66
              md-small-size-100
              md-xsmall-size-100
              md-medium-size-66
              mx-auto
              md-scrollbar
            "
          >
            <div>
              <status-card header-color="green">
                <template v-slot:title>
                  <h4 class="card-title">Neuer Status</h4>
                </template>
                <template v-slot:goal>
                  <span><b>Wochenziel:</b></span>
                  <p style="margin-left: 5px; overflow-wrap: anywhere">
                    {{ currentGoal.text }}
                  </p>
                  <span><b>Wochenziel: </b></span>
                  <span>{{ getLatestBattery() }} %</span>
                  <md-progress-bar
                    class="md-primary"
                    md-mode="determinate"
                    :md-value="getLatestBattery()"
                  ></md-progress-bar>
                  <span><b>Gültig in KW: </b>{{ currentGoal.week }}</span>
                </template>
                <template v-slot:description>
                  <p class="description">Wie geht es dir?</p>
                </template>
                <template v-slot:inputs>
                  <md-field class="md-form-group">
                    <md-icon>battery_unknown</md-icon>
                    <label
                      >Zu wieviel Prozent hast du dein Ziel erreicht?</label
                    >
                    <md-input v-model.number="battery" type="number"></md-input>
                  </md-field>
                  <md-field class="md-form-group">
                    <md-icon>add_reaction</md-icon>
                    <div
                      class="md-layout"
                      style="margin-left: 10px; margin-bottom: 30px"
                    >
                      <div v-for="e in emojies" v-bind:key="e.name">
                        <md-radio v-model="emoji" :value="e.name">
                          <md-avatar> <img :src="e.img" /> </md-avatar
                        ></md-radio>
                      </div>
                    </div>
                  </md-field>
                  <md-field class="md-form-group">
                    <md-icon>textsms</md-icon>
                    <label>Gab es was Besonderes heute?</label>
                    <md-input v-model="text"></md-input>
                  </md-field>
                  <md-button
                    class="md-simple md-success md-lg"
                    @click="sendStatus"
                  >
                    Speichern
                  </md-button>
                </template>
              </status-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StatusCard } from "@/components";

export default {
  components: {
    StatusCard,
  },
  bodyClass: "newStatus-page",
  data() {
    return {
      status: [],
      currentGoal: {
        _id: "",
        text: "",
        week: "",
      },
      text: "",
      emoji: "",
      battery: 0,
      showDialog: false,
      emojies: [
        { name: "e1", img: require("@/assets/img/emojis/e1.png") },
        { name: "e2", img: require("@/assets/img/emojis/e2.png") },
        { name: "e3", img: require("@/assets/img/emojis/e3.png") },
        { name: "e4", img: require("@/assets/img/emojis/e4.png") },
        { name: "e5", img: require("@/assets/img/emojis/e5.png") },
        { name: "e6", img: require("@/assets/img/emojis/e6.png") },
        { name: "e7", img: require("@/assets/img/emojis/e7.png") },
        { name: "e8", img: require("@/assets/img/emojis/e8.png") },
        { name: "e9", img: require("@/assets/img/emojis/e9.png") },
        { name: "e10", img: require("@/assets/img/emojis/e10.png") },
        { name: "e11", img: require("@/assets/img/emojis/e11.png") },
        { name: "e12", img: require("@/assets/img/emojis/e12.png") },
      ],
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background2.png"),
    },
    e1: {
      type: String,
      default: require("@/assets/img/emojis/e1.png"),
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    // Goals
    async addGoal(newGoal) {
      console.log("addGoal Goal.vue");

      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/goals", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newGoal),
      });

      const data = await res.json();
      console.log(data);
      // this.goals = [...this.goals, data];
    },
    async updateGoal(updatedGoal) {
      console.log(updatedGoal);
    },
    // async deleteGoal(_id) {
    //   const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`, {
    //     method: "DELETE",
    //   });

    //   res.status === 200
    //     ? (this.goals = this.goals.filter((goal) => goal._id !== _id))
    //     : alert("Error deleteing goal");
    // },
    async fetchAllGoals() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchGoal(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    async fetchGoalByWeek(week) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/week/${week}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    getCurrentYearandWeekNumber() {
      let date = new Date();

      let weekNumber = require("current-week-number");
      let year = date.getFullYear();
      let week = weekNumber(date);
      let yearAndWeek = year + "-W" + week;

      return yearAndWeek;
    },
    async fetchGoalsByWeek(week) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/week/${week}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    // Status
    async addStatus(newStatus) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/status", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newStatus),
      });

      const data = await res.json();
      this.status = [...this.status, data];
      this.battery = this.getLatestBattery();
    },
    async deleteStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      res.status === 200
        ? (this.status = this.status.filter((s) => s._id !== _id))
        : alert("Error deleteing status");
    },
    async fetchAllStatus() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/status`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    sendStatus() {
      // if (!this.text && !this.emoji) {
      //   console.log("Text:" + this.text + ", emoji:" + this.emoji);
      //   alert("Please add a status or emoji");
      //   return;
      // }

      const newStatus = {
        text: this.text,
        emoji: this.emoji,
        battery: this.battery,
      };

      console.log(newStatus);

      this.addStatus(newStatus);

      this.text = "";
      this.emoji = "";
    },
    getLatestBattery() {
      let battery = 0;

      for (let index = this.status.length - 1; index >= 0; index--) {
        const status = this.status[index];
        if (status.battery) {
          battery = status.battery;
          break;
        }
      }

      return battery;
    },
  },
  async created() {
    // Get the access token from the auth wrapper
    const token = await this.$auth.getTokenSilently();
    const user = await this.$auth.user;

    console.log("User: ");
    console.log(user);

    this.status = await this.fetchAllStatus({
      headers: {
        Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
      },
    });
    this.battery = this.getLatestBattery();

    const currentWeeek = this.getCurrentYearandWeekNumber();
    this.currentGoal = await this.fetchGoalsByWeek(currentWeeek);
  },
};
</script>

<style lang="css">
.md-content {
  max-width: 400px;
  max-height: 200px;
  overflow: auto;
}
</style>
