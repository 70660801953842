<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="
              md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100
            "
          >
            <h1 class="title">Wochenziel</h1>
            <h4>Ziel: {{ currentGoal.text }}</h4>
            <h4>Gültig in KW: {{ currentGoal.week }}</h4>
            <md-progress-bar
              class="md-info"
              md-mode="determinate"
              :md-value="getLatestBattery()"
            ></md-progress-bar>
            <h4>Battery:</h4>
            <input type="range" v-model.number="battery" /> {{ battery }}%
            <br />
            <md-button class="md-raised" @click="updateBattery">
              <md-icon>edit</md-icon> Update Batterie
            </md-button>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-100 md-alignment-top-center mx-auto"
            >
              <h2 class="title text-center">Neues Wochenziel</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  bodyClass: "weekgoal-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background4.png"),
    },
  },
  components: {},
  data() {
    return {
      status: [],
      currentGoal: {
        _id: "",
        text: "",
        week: "",
      },
      text: "",
      emoji: "",
      battery: 0,
      showDialog: false,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    selectEmoji(emoji) {
      console.log(emoji);
      this.showDialog = false;
      this.emoji = emoji.data;
      this.sendStatus();
    },
    updateBattery() {
      const newStatus = {
        battery: this.battery,
      };

      this.addStatus(newStatus);
    },
    // Goals
    async addGoal(newGoal) {
      console.log("addGoal Goal.vue");

      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/goals", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newGoal),
      });

      const data = await res.json();
      console.log(data);
      // this.goals = [...this.goals, data];
    },
    async updateGoal(updatedGoal) {
      console.log(updatedGoal);
    },
    // async deleteGoal(_id) {
    //   const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`, {
    //     method: "DELETE",
    //   });

    //   res.status === 200
    //     ? (this.goals = this.goals.filter((goal) => goal._id !== _id))
    //     : alert("Error deleteing goal");
    // },
    async fetchAllGoals() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchGoal(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    async fetchGoalByWeek(week) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/week/${week}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    getCurrentYearandWeekNumber() {
      let date = new Date();

      let weekNumber = require("current-week-number");
      let year = date.getFullYear();
      let week = weekNumber(date);
      let yearAndWeek = year + "-W" + week;

      return yearAndWeek;
    },
    async fetchGoalsByWeek(week) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/week/${week}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    // Status
    async addStatus(newStatus) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/status", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newStatus),
      });

      const data = await res.json();
      this.status = [...this.status, data];
      this.battery = this.getLatestBattery();
    },
    async deleteStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      res.status === 200
        ? (this.status = this.status.filter((s) => s._id !== _id))
        : alert("Error deleteing status");
    },
    async fetchAllStatus() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/status`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    sendStatus() {
      if (!this.text && !this.emoji) {
        console.log("Text:" + this.text + ", emoji:" + this.emoji);
        alert("Please add a status or emoji");
        return;
      }

      const newStatus = {
        text: this.text,
        emoji: this.emoji,
      };

      this.addStatus(newStatus);

      this.text = "";
      this.emoji = "";
    },
    getLatestBattery() {
      let battery = 0;

      for (let index = this.status.length - 1; index >= 0; index--) {
        const status = this.status[index];
        if (status.battery) {
          battery = status.battery;
          break;
        }
      }

      return battery;
    },
  },
  async created() {
    // Get the access token from the auth wrapper
    const token = await this.$auth.getTokenSilently();

    this.status = await this.fetchAllStatus({
      headers: {
        Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
      },
    });
    this.battery = this.getLatestBattery();

    const currentWeeek = this.getCurrentYearandWeekNumber();
    this.currentGoal = await this.fetchGoalsByWeek(currentWeeek);
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
