<template>
  <md-content class="md-scrollbar">
    <div :key="s._id" v-for="s in status">
      <Message :status="s" :isUser="true" :picture="$auth.user.picture" />
      <Message :status="{ text: 'Super!' }" :isUser="false" />
    </div>
  </md-content>
</template>

<script>
import Message from "./Message";

export default {
  name: "Chat",
  components: {
    Message,
  },
  props: {
    status: Array,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scope>
.md-content {
  max-width: 100%;
  max-height: 400px;
  overflow-wrap: anywhere;
}
</style>
